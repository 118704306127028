import { SavedPhoneFilter } from '@capturi/api-filters'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import {
  PeriodDefinition,
  PeriodDefinitions,
  SingleSegmentContainer,
  useFilterDefinitions,
} from '@capturi/filters'
import { usePageTitle } from '@capturi/react-utils'
import { PageHeading } from '@capturi/ui-components'
import { Box, Grid, HStack, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { useParams } from 'react-router-dom'

import { DateDialog } from '../components/DateDialog'
import LiveTrendsContainer from '../components/LiveTrendsContainer'
import AnalysisContext from '../contexts/AnalysisContext'
import {
  LiveTrendsProvider,
  useLiveTrendsContext,
} from '../contexts/LiveTrendsContext'
import { PeriodProvider } from '../contexts/PeriodContext'
import { RoleProvider, useRoleContext } from '../contexts/RoleContext'
import TrendTypeContext from '../contexts/TrendTypeContext'
import { useLogEvent } from '../events'
import useTrendsNavigation from '../hooks/useTrendsNavigation'
import useTrendRoutes from '../routes'

const LiveTrendsPage: React.FC = () => {
  usePageTitle(t`Live trend calculation`)
  const trendRoutes = useTrendRoutes()
  const trendsNavigate = useTrendsNavigation(trendRoutes.liveTrends)
  const [role] = useRoleContext()
  const { data } = useLiveTrendsContext()
  const roleData = data?.[role]
  const logEvent = useLogEvent()

  const currentUser = useCurrentUser()

  const filterDefinitions = useFilterDefinitions(currentUser, {})
  const handleSegmentSaved = React.useCallback(
    (segment: SavedPhoneFilter, isNew: boolean) => {
      if (isNew) {
        trendsNavigate({ sourceId: segment.uid })
      }
      logEvent('filter--saved', { segment: segment.name })
    },
    [logEvent, trendsNavigate],
  )

  const handleSegmentChanged = React.useCallback(
    (segment: SavedPhoneFilter | undefined) => {
      if (segment) {
        trendsNavigate({ sourceId: segment.uid })
        logEvent('filter--loaded', { segment: segment?.name })
      } else {
        logEvent('filter--cleared')
      }
    },
    [logEvent, trendsNavigate],
  )

  return (
    <Box>
      <Grid
        templateColumns={{ lg: '1fr auto' }}
        gap="1rem"
        alignItems="center"
        mb={8}
      >
        <Box>
          <PageHeading width="fit-content">{t`Live trend calculation`}</PageHeading>
          <Text color="textMuted">
            <Trans>Trend reports on demand for up to 400 conversations</Trans>
          </Text>
        </Box>

        <HStack spacing={4} justify="flex-end" alignItems="center">
          <Text fontSize="sm">
            {roleData?.data?.processingStatus !== 'InsufficientDataError' &&
              !roleData?.isLoading &&
              roleData?.resultsInfo && (
                <Trans>
                  Conversations: {roleData?.resultsInfo?.conversationsUsed}
                </Trans>
              )}
          </Text>
          <Box ml="auto">
            <DateDialog
              onSelectPeriodDef={(pd: PeriodDefinition) => {
                logEvent('date-period--changed', pd)
              }}
            />
          </Box>
        </HStack>
      </Grid>
      <Box my={8}>
        <SingleSegmentContainer
          filterDefinitions={filterDefinitions}
          onStateSaved={handleSegmentSaved}
          onSavedFilterChange={handleSegmentChanged}
          showExportButton={false}
          showNotificationButton={false}
        />
      </Box>
      <LiveTrendsContainer />
    </Box>
  )
}

export default function LiveTrendsPageRoute(): React.ReactElement {
  const { liveTopicsUid } = useParams()
  const { employeeAsDefaultSpeakerTab } = useFeatureFlags()

  const analysisContext = React.useMemo(
    () => ({
      useLiveData: true,
      sourceId: liveTopicsUid,
    }),
    [liveTopicsUid],
  )

  return (
    <RoleProvider defaultRole={employeeAsDefaultSpeakerTab ? 'Agent' : 'All'}>
      <PeriodProvider defaultPeriod={PeriodDefinitions.Today}>
        <LiveTrendsProvider>
          <TrendTypeContext.Provider value="Live">
            <AnalysisContext.Provider value={analysisContext}>
              <LiveTrendsPage />
            </AnalysisContext.Provider>
          </TrendTypeContext.Provider>
        </LiveTrendsProvider>
      </PeriodProvider>
    </RoleProvider>
  )
}
