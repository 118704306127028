import { FixedPeriodDefinition, PeriodDefinition } from '@capturi/filters'
import { DatePicker } from '@capturi/ui-components'
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import React from 'react'

import { usePeriodContext } from '../contexts/PeriodContext'
import PeriodDefButton from './PeriodDefButton'

const DateDialogContent: React.FC<{
  value?: PeriodDefinition
  onApply: (def: PeriodDefinition) => void
}> = ({ value, onApply }) => {
  const selectedDate = React.useMemo(() => {
    if (value === undefined) return undefined
    const { from } = value.create(new Date())
    return from
  }, [value])

  const onSelectDate = (date: Date): void => {
    const def = new FixedPeriodDefinition(date, date)
    onApply(def)
  }

  return (
    <DatePicker
      value={selectedDate}
      showInputs
      disabled={[{ after: new Date() }]}
      onSelectDate={onSelectDate}
    />
  )
}

export const DateDialog: React.FC<{
  onSelectPeriodDef?: (def: PeriodDefinition) => void
}> = ({ onSelectPeriodDef }) => {
  const { periodDef, setPeriodDef } = usePeriodContext()

  return (
    <Popover placement="auto-end" variant="responsive">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <PeriodDefButton />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody p={0}>
              <DateDialogContent
                value={periodDef}
                onApply={(value) => {
                  setPeriodDef(value)
                  onSelectPeriodDef?.(value)
                  onClose()
                }}
              />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
